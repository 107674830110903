import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"menu"},[_c('div',{staticClass:"page-title"},[_c('div',[_vm._v("Users")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.createDialog = true}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v("Create User ")],1)],1)]),_c('users-table'),_c(VDialog,{attrs:{"persistent":_vm.isLoadingCreate,"max-width":"600px"},model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v("Create User")])]),_c(VCardText,[_c(VTextField,{attrs:{"label":"Email","required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm._createUser.apply(null, arguments)}},model:{value:(_vm.newUserEmail),callback:function ($$v) {_vm.newUserEmail=$$v},expression:"newUserEmail"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"disabled":_vm.isLoadingCreate,"color":"grey darken-1","text":""},on:{"click":function($event){_vm.createDialog = false}}},[_vm._v("Close")]),_c(VSpacer),_c(VBtn,{attrs:{"loading":_vm.isLoadingCreate,"color":"primary","text":""},on:{"click":_vm._createUser}},[_vm._v("Create")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }